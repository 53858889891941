.containerpaginaContact {
    height: 524px;
    position: relative;
}

.PcontainerParagrafoForm {
    position: relative;
}

.containerParagrafoForm {
    position: relative;
    bottom: 350px;
    font-size: 13px;
}
.contact-container {
    max-width: 600px;
    max-height: 450px;
    top: 20px;
    transform: translateY(30px);
    margin: 50px auto;
    padding: 24px;
    box-shadow: -1px -1px 10px rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    font-family: Arial, sans-serif;
    text-align: center;
    position: relative;
}

.Pcontact-header {
    position: relative;
}

.contact-header {
    font-size: 40px;
    font-weight: bold;
    bottom: 110px;
    position: relative;
}

form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

.input-group {
    display: flex;
    gap: 10px;
    width: 100%;
}

.input-group input {
    width: 50%;
}

input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    outline: none;
}

input:focus {
    border: 2px solid var(--text-color-ParagrafoForm);
}

.ContainerEmailSubject {
    display: flex; /* Dispone gli elementi in riga */
    gap: 10px; /* Distanza tra i due input */
    align-items: center; /* Allinea verticalmente */
    width: 100%;
}

.barraEmail {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%; /* Adatta la larghezza */
}

.boxBarraEmail {
    flex-grow: 1; /* Permette al secondo input di occupare lo spazio disponibile */
}
.barraSubject {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%; /* Adatta la larghezza */
}

.boxBarraSubject {
    flex-grow: 1; /* Permette al secondo input di occupare lo spazio disponibile */
}

.PTextareaContainer {
    position: relative;
    bottom: 0px;
}

.TextareaContainer {
    position: relative;
    width: 553px;
    height: 400px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
    outline: none;
}

/* .TextareaContainer:focus{
  border: 2px solid var(--text-color-ParagrafoForm);
} */

textarea {
    outline: none;
    resize: vertical;
    width: 100%;
    max-height: 150px;
    min-height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
}

textarea:focus {
    border: 2px solid white;
}

.ContainerbtnContact {
    justify-content: center;
    position: relative;
}

.btnContact {
    background-color: #23482c;
    color: #2d8943;
    padding: 12px 20px;
    border: none;
    border-radius: 6px;
    font-size: 16px;
    transition: background 0.3s;
    display: flex;
    justify-content: center;
    position: relative;
    width: 200px;
    bottom: 50px;
}

.btnContact:hover {
    color: #33cb59;
}

.Container-social-botton {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    height: auto;
    width: 60px;
    bottom: 220px;
    left: 1430px;
    padding: 10px;
    border-radius: 10px;
}

.Container-social-botton a {
    color: var(--text-color-paragrf);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); */
    transition: transform 0.2s ease-in-out;
    text-decoration: none;
    box-shadow: 0px 1px 10px rgba(112, 112, 112, 0.1);
}

.Container-social-botton a:hover {
    color: white;
}

.Container-social-botton a:hover {
    transform: scale(1.1);
}

.whatsapp-button:hover {
    background: linear-gradient(
        45deg,
        #25d366,
        #25d366,
        #25d366,
        #25d366,
        #25d366,
        #25d366,
        #25d366,
        #25d366,
        #25d366,
        #25d366
    );
}

.Instangram-buttom:hover {
    background: linear-gradient(
        45deg,
        #ffdc80,
        #fcaf45,
        #f77737,
        #f56040,
        #fd1d1d,
        #e1306c,
        #c13584,
        #833ab4,
        #5b51d8,
        #405de6
    );
}

.Linkedin-buttom:hover {
    background: linear-gradient(
        45deg,
        #0077b5,
        #0077b5,
        #0077b5,
        #0077b5,
        #0077b5,
        #0077b5,
        #0077b5,
        #0077b5,
        #0077b5,
        #0077b5
    );
}

.Telegram-buttom:hover {
    background: linear-gradient(
        45deg,
        #0088cc,
        #0088cc,
        #0088cc,
        #0088cc,
        #0088cc,
        #0088cc,
        #0088cc,
        #0088cc,
        #0088cc,
        #0088cc
    );
}

.social-icon {
    font-size: 25px;
}
