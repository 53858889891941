/* Careers Contact Section */
#careers-contact {
    padding: 60px 20px;
    background-color: var(--background-color);
    color: var(--text-color);
}

#careers-contact .container {
    max-width: 1200px;
    margin: 0 auto;
}

#careers-contact .text-center {
    margin-bottom: 40px;
}

#careers-contact h2 {
    font-size: 2.5rem;
    color: var(--text-color-primary);
}

#careers-contact p {
    font-size: 1.1rem;
    color: var(--text-color-secondary);
}

#careers-contact .cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

#careers-contact .card {
    background-color: var(--card-background-color);
    border: 1px solid var(--card-background-color);
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition:
        transform 0.3s,
        box-shadow 0.3s;
}

#careers-contact .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

#careers-contact .card .icon {
    max-width: 80px;
    margin-bottom: 20px;
}

#careers-contact .card h4 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: var(--card-text-color);
}

#careers-contact .card p {
    font-size: 1rem;
    margin-bottom: 20px;
    color: var(--card-text-color);
}

#careers-contact .card .btn {
    background-color: var(--primary-color);
    color: #fff;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color 0.3s;
}

#careers-contact .card .btn:hover {
    background-color: var(--secondary-color);
}

/* Responsive Design */
@media (max-width: 768px) {
    #careers-contact h2 {
        font-size: 2.2rem;
    }

    #careers-contact p {
        font-size: 1.2rem;
    }

    #careers-contact .card {
        width: 100%;
    }

    #careers-contact .card .icon {
        max-width: 60px;
    }
}
