/* Portfolio Section */
.portfolio-section {
    padding: 80px 20px;
    background: linear-gradient(
        180deg,
        var(--background-color-light),
        var(--background-color-dark)
    );
    color: var(--text-color-primary);
}

/* Portfolio Container */
.container {
    max-width: 1200px;
    margin: 0 auto;
}

/* Header Section */
.header {
    text-align: center;
    margin-bottom: 50px;
}

.portfolio-title {
    font-size: 2.8rem;
    font-weight: 700;
    color: var(--primary-color);
    margin-bottom: 10px;
}

.portfolio-subtitle {
    font-size: 1.4rem;
    color: var(--text-color-secondary);
    line-height: 1.6;
}

/* Portfolio Cards Container */
.portfolio-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    padding: 0 10px;
}

/* Individual Card */
.portfolio-card {
    background: var(--card-background);
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
    transition:
        transform 0.3s ease,
        box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.portfolio-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
}

/* Card Image Section */
.card-image {
    height: 250px;
    background: var(--background-color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.card-image img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.portfolio-card:hover .card-image img {
    transform: scale(1.05);
}

/* Card Content */
.card-content {
    padding: 20px;
    text-align: center;
}

.card-title {
    font-size: 1.6rem;
    font-weight: 700;
    color: var(--text-color-primary);
    margin-bottom: 10px;
}

.card-subtitle {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--text-color-secondary);
    margin-bottom: 15px;
}

.card-description {
    font-size: 1rem;
    color: var(--text-color-secondary);
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Card Actions (Buttons) */
.card-actions {
    display: flex;
    justify-content: center;
    gap: 15px;
    padding: 20px;
}

.card-actions .btn {
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 1rem;
    font-weight: 600;
    transition:
        background-color 0.3s ease,
        transform 0.2s ease;
    cursor: pointer;
    text-align: center;
}

.btn-primary {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
}

.btn-primary:hover {
    background-color: var(--primary-color-dark);
    transform: translateY(-2px);
}

.btn-secondary {
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
}

.btn-secondary:hover {
    background-color: var(--secondary-color-dark);
    transform: translateY(-2px);
}

/* Responsive Design */
@media (max-width: 768px) {
    .portfolio-title {
        font-size: 2.2rem;
    }

    .portfolio-subtitle {
        font-size: 1.2rem;
    }

    .card-content {
        padding: 15px;
    }

    .card-title {
        font-size: 1.4rem;
    }

    .card-subtitle {
        font-size: 1rem;
    }

    .btn {
        font-size: 0.9rem;
        padding: 8px 16px;
    }

    .card-image {
        height: 200px;
    }
}
