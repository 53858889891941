#footer {
    background-color: #02395a;
    text-align: center;
    padding: 5rem;
}

#footer * {
    color: #fff;
}

#footer small {
    font-size: 14px;
    font-weight: 200;
    letter-spacing: 2px;
}

@media screen and (max-width: 640px) {
    #footer h2 {
        font-size: 35px;
    }
}

#footer h6 {
    font-weight: 200;
    max-width: 550px;
    line-height: 33px;
}

#footer .copyright {
    font-weight: 200;
    font-size: 14px;
}

#footer hr {
    border-top: 0.5px solid #fff;
    opacity: 0.2;
    margin: 3rem 0;
}

#footer .nav,
#footer .nav .social,
#footer .nav .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#footer .proudly-swiss {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    color: #fff;
    text-align: center;
}

#footer .proudly-swiss .swiss-flag {
    width: 50px;
    margin-bottom: 1rem;
}

#footer .proudly-swiss .swiss-message {
    font-size: 16px;
    font-weight: 300;
    margin: 1rem 0;
}

#footer .proudly-swiss .swiss-values {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 1.5rem 0;
}

#footer .proudly-swiss .swiss-values .value-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

#footer .proudly-swiss .swiss-values .value-item img {
    width: 40px;
    margin-bottom: 0.5rem;
}

#footer .proudly-swiss .swiss-values .value-item p {
    font-size: 14px;
    font-weight: 400;
}

#footer .proudly-swiss .learn-more {
    margin-top: 1.5rem;
}

#footer .proudly-swiss .learn-more-btn {
    background-color: #e30613;
    color: #fff;
    border: none;
    padding: 0.8rem 1.5rem;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

#footer .proudly-swiss .learn-more-btn:hover {
    background-color: #b0050f;
}

@media screen and (max-width: 745px) {
    #footer .nav {
        flex-wrap: wrap;
        justify-content: center;
    }
}

#footer .nav .logo img {
    width: 128px;
}

@media screen and (max-width: 500px) {
    #footer .nav .logo {
        width: 100%;
        text-align: center;
    }
    #footer .nav .nav {
        width: 100%;
        margin-top: 1.5rem;
    }
}

#footer .nav .nav .nav-item {
    margin: 0 1rem;
}

@media screen and (max-width: 991px) {
    #footer .nav .nav .nav-item a {
        font-size: 14px;
    }
}

#footer .nav .social .item {
    width: 45px;
    margin: 0 0.5rem;
}

@media screen and (max-width: 991px) {
    #footer .nav .social .item {
        width: 35px;
    }
}

#footer .nav .social .item img {
    width: 100%;
}

/* Proudly Swiss section */
#footer .proudly-swiss {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

#footer .proudly-swiss .swiss-flag {
    width: 50px;
    margin-bottom: 1rem;
}

#footer .proudly-swiss p {
    font-size: 16px;
    font-weight: 300;
}
