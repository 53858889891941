.heroSection,
.trendsSection,
.marketingSection,
.successStoriesSection,
.proMembershipSection {
    /* min-height: 100vh */
    padding: 80px 0;
    display: flex;
    align-items: center;
}

.emoji {
    font-size: 1.5rem;
}

.signupForm {
    max-width: 500px;
}

.imageWrapper {
    position: relative;
    padding-top: 2rem;
}

.mainImage,
.trendImage,
.marketingImage {
    background-color: #ffc107;
    padding: 2rem;
    text-align: center;
}

.mainImage img,
.trendImage img,
.marketingImage img {
    max-height: 400px;
    object-fit: cover;
}

.statsCard,
.trendCard,
.marketingCard {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    max-width: 400px;
}

.avatarGroup {
    display: flex;
    margin-bottom: 0.5rem;
}

.avatarGroup img {
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    margin-right: -10px;
}

/* .urgencyBanner {
    border-left: 5px solid;
    border-radius: 12px;
    animation: pulse 2s infinite;
} */

.countdownTimer {
    font-size: 1.2rem;
}

.timerDigits {
    font-weight: bold;
    font-family: monospace;
}

.trendIcon,
.marketingIcon {
    width: 40px;
    height: 40px;
}

.ctaWrapper {
    text-align: center;
}

.testimonial {
    transition: transform 0.3s ease-in-out;
}

.testimonial:hover {
    transform: translateY(-5px);
}

.proCard {
    border-top: 5px solid #007bff;
}

.priceBanner {
    background-color: #007bff;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
    }
}

@media (max-width: 991.98px) {
    .heroSection,
    .trendsSection,
    .marketingSection,
    .successStoriesSection,
    .proMembershipSection {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .imageWrapper {
        margin-top: 2rem;
    }

    .statsCard,
    .trendCard,
    .marketingCard {
        position: relative;
        bottom: auto;
        left: auto;
        max-width: none;
        margin-top: 1rem;
    }
}

@media (max-width: 575.98px) {
    .mainImage,
    .trendImage,
    .marketingImage {
        padding: 1rem;
    }

    .mainImage img,
    .trendImage img,
    .marketingImage img {
        max-height: 300px;
    }

    .countdownTimer {
        font-size: 1rem;
    }
}
