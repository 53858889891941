/* Navbar Styles */
#navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 1.5rem;
    background-color: #f5f5f5; /* Default for light theme */
    z-index: 100000 !important;
    transition:
        background-color 0.3s ease,
        color 0.3s ease;
}

/* Navbar Text Color */
#navbar * {
    color: inherit;
    transition: color 0.3s ease;
}

/* Container Fluid */
#navbar .container-fluid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Navbar Navigation */
#navbar .nav {
    display: flex;
    align-items: center;
    transition: 0.4s ease;
}

/* Navbar Active (Hamburger) */
@media screen and (max-width: 767px) {
    #navbar .nav {
        position: fixed;
        height: 100vh;
        flex-direction: column;
        background-color: #fff; /* Light theme background */
        top: 0;
        padding: 2rem;
        width: 100%;
        left: -100%;
        transition:
            left 0.3s ease,
            background-color 0.3s ease;
    }

    #navbar .nav.active {
        left: 0;
    }

    body[data-theme='dark'] #navbar .nav {
        background-color: #282828; /* Dark theme background */
    }
}

/* Close Button */
#navbar .nav #close {
    font-size: 2.5rem;
    position: absolute;
    right: 2rem;
    top: 0rem;
}

@media screen and (min-width: 768px) {
    #navbar .nav #close {
        display: none;
    }
}

/* Navbar Items */
#navbar .nav .nav-item {
    padding: 0 1.5rem;
    display: block;
    transition: color 0.3s ease;
}

/* Small Screen Adjustments */
@media screen and (max-width: 991px) {
    #navbar .nav .nav-item {
        padding: 0 1rem;
    }
}

@media screen and (max-width: 767px) {
    #navbar .nav .nav-item {
        width: 100%;
        border-bottom: 1px solid #e0e0e0; /* Light theme divider */
        text-align: center;
    }

    body[data-theme='dark'] #navbar .nav .nav-item {
        border-bottom: 1px solid #373737; /* Dark theme divider */
    }
}

/* Last Item Padding */
#navbar .nav .nav-item:last-of-type {
    padding-right: 0;
}

/* Nav Item Links */
#navbar .nav .nav-item a {
    position: relative;
    display: inline-block;
    transition: color 0.3s ease;
}

/* Adjust Font Size on Smaller Screens */
@media screen and (max-width: 991px) {
    #navbar .nav .nav-item a {
        font-size: 14px;
    }
}

@media screen and (max-width: 767px) {
    #navbar .nav .nav-item a {
        padding: 1rem 0;
    }

    #navbar .nav .nav-item a:not(.btn-header) {
        width: 100%;
    }
}

/* Header Button Styling */
#navbar .nav .nav-item .btn-header {
    border: 1px solid #2baa4a;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    transition:
        0.4s ease,
        background-color 0.3s ease,
        color 0.3s ease;
}

/* Button Hover Effect */
#navbar .nav .nav-item .btn-header:hover {
    opacity: 1;
    background-color: #2baa4a; /* Default green hover */
    border-color: transparent;
    color: #fff;
}

/* Logo Styles */
.logo {
    width: 10.75rem;
}

@media screen and (max-width: 991px) {
    .logo {
        width: 8rem;
    }
}

.logo img {
    width: 100%;
}

/* Hamburger Menu Styles */
#hamburger > * {
    border-radius: 10px;
    width: 30px;
    height: 3px;
    margin-bottom: 7px;
    background-color: var(--navbar-text-color);
}

#hamburger:hover div {
    background-color: var(--primary-color);
}

/* Nav Item Underline Effect */
.nav-item a {
    position: relative;
}

.nav-item a:not(.btn-header):after {
    content: '';
    position: absolute;
    background-color: #2baa4a;
    width: 0;
    height: 2px;
    left: 50%;
    bottom: -5px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
}

.nav-item a:hover:not(.btn-header):after {
    width: 100%;
}
