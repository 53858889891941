/* Overlay for the popup */
.language-selection-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup styling */
.language-selection-popup {
    background-color: var(--background-color);
    border: 1px solid var(--text-color-secondary);
    padding: 20px;
    border-radius: 8px;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
}

/* Close button */
.close-popup-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: var(--text-color-primary);
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
}

.close-popup-button:hover {
    transform: scale(1.2);
}

/* Popup content */
.language-selection-popup ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.language-selection-popup li {
    margin-bottom: 10px;
}

.popup-title {
    font-size: 1.2rem;
    color: var(--text-color-primary);
    margin-bottom: 15px;
    font-weight: bold;
}

.language-selection-popup button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: var(--text-color-primary);
    font-family: var(--font-family);
    font-size: 1rem;
    gap: 10px;
    transition: background 0.2s ease-in-out;
}

.language-selection-popup button:hover {
    background-color: var(--primary-color);
    color: #fff;
    border-radius: 5px;
}

.flag-icon {
    width: 24px;
    height: 16px;
    border-radius: 2px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
